import { api, router } from '@moveup/app-core';
require('json.date-extensions');

const axiosJSONDateParseParams = {
	transformResponse: data => JSON.parseWithDate(data),
};

const initialState = {
	isShowingEmpty: false,
	isShowingIncompleteOnly: false,
	fromLanguage: undefined,
	toLanguage: undefined,
};

const store = {
	state: initialState,
	mutations: {
		toggleIsShowingEmpty(state) {
			state.isShowingEmpty = !state.isShowingEmpty;
		},
		toggleIsShowingIncompleteOnly(state) {
			state.isShowingIncompleteOnly = !state.isShowingIncompleteOnly;
		},
		setFromLanguage(state, language) {
			state.fromLanguage = language;
			const matchedPath = router?.currentRoute?.matched[0]?.path;
			if (router?.currentRoute?.matched[0]?.path) {
				const { source, reference, to } = router?.currentRoute?.params
				const newPath = matchedPath
					.replace(':source', source)
					.replace(':reference', reference)
					.replace(':from', language)
					.replace(':to', to)
				history.replaceState({}, null, newPath)
			}
		},
		setToLanguage(state, language) {
			state.toLanguage = language;
			const matchedPath = router?.currentRoute?.matched[0]?.path;
			if (router?.currentRoute?.matched[0]?.path) {
				const { source, reference, from } = router?.currentRoute?.params
				const newPath = matchedPath
					.replace(':source', source)
					.replace(':reference', reference)
					.replace(':from', from)
					.replace(':to', language)
				history.replaceState({}, null, newPath)
			}
		},
	},
	getters: {},
	actions: {
		toggleIsShowingEmpty({ commit }) {
			commit('toggleIsShowingEmpty');
		},
		toggleIsShowingIncompleteOnly({ commit }) {
			commit('toggleIsShowingIncompleteOnly');
		},
		setFromLanguage({ commit }, language) {
			commit('setFromLanguage', language);
		},
		setToLanguage({ commit }, language) {
			commit('setToLanguage', language);
		},

		getContextSources(_, params) {
			return api.get('context/sources', params);
		},

		getLanguages(_, params) {
			return api.get('language', params);
		},

		getTranslations(_, params) {
			return api.get('translations', params);
		},

		getSuggestions(_, params) {
			const { from, to, list } = params;
			console.log('params:', params);
			if (from && to && list && Array.isArray(list))
				return api.post(`suggestions/${from}/${to}`, list);
		},

		getUsage(_, params) {
			return api.get('suggestions/usage', params);
		},

		updateTranslation(_, translation) {
			if (!translation?._id) return;
			return api.patch(`translations/${translation._id}`, translation);
		},

		upsertTranslations(_, translations) {
			return api.post(`translations`, translations);
		},
	},
};

export default store;
